import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kreditvertrag = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Der Kreditvertrag – das musst du wissen" showCalc={false} />
            <Article>
                <p>
                    Beim Kauf deines neuen Zuhauses kommst du langsam in die heiße Phase? Die Finanzierung ist geplant,
                    das Objekt ausgewählt und auch der Kaufvertrag harrt schon deiner Signatur? Was auch unterschrieben
                    sein will, ist der Kreditvertrag. Wie dieser aussieht und was du beachten musst, das erfährst du im
                    folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was genau ist ein Kreditvertrag?</h2>
                <p>
                    Widmen wir uns zunächst einmal der Definition. Unter einem Kreditvertrag kannst du dir einen Urkunde
                    vorstellen, die die Konditionen festhält, zu denen eine Partei Geld an eine andere verleiht. In den
                    meisten Fällen handelt es sich hier um Kreditinstitute, die Privatpersonen Kapital für eine größere
                    Anschaffung zur Verfügung stellen. Dabei ist der Kreditvertrag verpflichtend in Schriftform
                    abzuschließen. Anders bei der Vergabe von{" "}
                    <Link to="/artikel/kredit-tipps/" target="_blank" rel="noreferrer noopener">
                        Krediten
                    </Link>{" "}
                    im Privatbereich – etwa zwischen Familienmitgliedern: Hier kann der Vertragsschluss auch mündlich
                    erfolgen. Im Kreditvertrag werden sämtliche Rechte und Pflichten von Gläubiger:in und Schuldner:in
                    festgehalten.
                </p>
                <hr />

                <h2>Welche Bestandteile hat ein Kreditvertrag?</h2>
                <p>In Österreich hat ein Kreditvertrag für die Finanzierung einer Immobilie folgende Bestandteile:</p>
                <ul>
                    <li>Name und Anschrift der Vertragspartner:innen</li>
                    <li>Definition der Kreditart</li>
                    <li>Gesamtkreditbetrag, d.h. die Kreditsumme inklusive aller Nebenkosten</li>
                    <li>Auszahlungsbetrag, d.h. der Gesamtkreditbetrag exklusive der jeweiligen Nebenkosten</li>
                    <li>
                        <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                            Zinsen
                        </Link>{" "}
                        und Entgelte
                    </li>
                    <li>Laufzeit</li>
                    <li>Verwendungszweck</li>
                    <li>
                        Kosten für die notarielle Beglaubigung der Unterschrift, für die Eintragung der{" "}
                        <Link to="/artikel/hypothekendarlehen/" target="_blank" rel="noreferrer noopener">
                            Hypothek
                        </Link>{" "}
                        im Grundbuch sowie für Schätzgebühren
                    </li>
                    <li>
                        Konsumentenschutzbestimmungen: Rücktrittsrecht, Ehegattenbestimmung bei Scheidung und
                        Datenschutzerklärung
                    </li>
                    <li>Ort und Datum der Unterschrift</li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Kann man von einem Kreditvertrag zurücktreten?</h2>
                <p>
                    Ein Rücktrittsrecht ist grundsätzlich immer gegeben. Allerdings ist es je nach Kreditart
                    unterschiedlich lang. Im Falle eines unbesicherten Konsumkredits hast du die Möglichkeit, den
                    Vertrag innerhalb von 14-Tagen nach der Unterzeichnung zu widerrufen. Deutlich weniger Zeit hast du
                    bei einem Hypothekarkredit. Das Hypothekar- und Immobilienkreditgesetz sieht hier eine Frist von
                    lediglich 2 Tagen nach der Unterfertigung vor. Allerdings nur dann, wenn das Kreditinstitut dir das
                    ESIS (europäisches standardisiertes Informationsmerkblatt) in der vorgeschriebenen Frist vorgelegt
                    hat und es von dir unterzeichnet wurde.
                </p>
                <p>
                    Du willst dich allgemein über mögliche Konditionen einer Immobilienfinanzierung informieren? Dann
                    ist unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    genau das Richtige für dich!
                </p>
                <hr />

                <h2>Soll ich den Kreditvertrag prüfen lassen?</h2>
                <p>
                    Verträge über Immobilienkredite sind facettenreich und komplex. Für Laien ist es daher besonders
                    schwierig, den Überblick über sämtliche zentralen Aspekte zu behalten. Folglich ist es
                    empfehlenswert, den Kreditvertrag deiner Bank vor der Unterzeichnung von unabhängiger Stelle prüfen
                    zu lassen. So stellst du sicher, dass dir tatsächlich nichts entgeht, und sicherst dir die optimalen
                    Konditionen für deine Finanzierung. Auch bei der Durchsicht deines Kreditvertrags kannst du
                    natürlich auf die Finanzierungsexpert:innen von miracl zählen!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kostenloses Erstgespräch vereinbaren
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"kreditvertrag"}></BreadcrumbList>
            <ArticleStructuredData page={"kreditvertrag"} heading={"Der Kreditvertrag – das musst du wissen"} />
        </Layout>
    );
};

export default Kreditvertrag;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kreditvertrag", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
